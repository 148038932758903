<template>
  <div>
    <CModalExtended
      :title="titleModal"
      color="dark"
      :show.sync="showModal"
      :closeOnBackdrop="false"
    >
      <template #header>
        <h5 class="modal-title">{{titleModal}}</h5>
        <button type="button" aria-label="Close" class="close" @click="closeModal">×</button>
      </template>
      <CRow class="justify-content-center">
        <CCol sm="12" lg="10">
          <CInput
            size="sm"
            v-uppercase
            :label="$t('label.CurrentSeal')"
            :horizontal="{label:'col-sm-12 col-lg-4 text-right', input:'col-sm-12 col-lg-8'}"
            :is-valid="hasError(true)"
            :placeholder="$t('label.CurrentSeal')"
          />
          <CInput
            size="sm"
            v-uppercase
            :label="$t('label.NewSeal')"
            :horizontal="{label:'col-sm-12 col-lg-4 text-right', input:'col-sm-12 col-lg-8'}"
            :is-valid="hasError(true)"
            :placeholder="$t('label.NewSeal')"
          />
          <CSelect
            size="sm"
            :label="$t('label.AssignedBy')"
            :horizontal="{label:'col-sm-12 col-lg-4 text-right', input:'col-sm-12 col-lg-8'}"
            :placeholder="$t('label.select')"
            v-model="AssignedBy"
            :options="AssignedByOptions"
          />
          <CTextarea
            rows="2"
            :label="$t('label.observation')"
            addLabelClasses="text-right required"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
            :placeholder="$t('label.description')"
            v-uppercase
            size="sm"
            :is-valid="hasError(true)"
            :invalid-feedback="errorMessage(true)"
          />
        </CCol>
      </CRow>

      <template #footer>
        <CButton
          color="add"
          class="d-flex align-items-center"
          @click.stop=""
          :disabled="isSubmit"
        >
          <CIcon name="checkAlt"/><span class="ml-1">{{ $t('button.accept')}} </span>
        </CButton>
        <CButton
          color="wipe"
          :disabled="isSubmit"
          class="d-flex align-items-center"
          @click.stop="closeModal"
        >
          <CIcon name="x"/><span class="ml-1">{{ $t('button.cancel')}}</span>
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import General from '@/_mixins/general';
import inspectionValidation from '@/_validations/inspection/inspectionValidation';
import PictureInput from '@/components/picture-input';


function data() {
  return {
    Route: '',
    showModal: false,
    showImage: false,
    titleModal: '',
    loadingOverlay: false,
    AssignedBy: '',
    AssignedByList: [],
    imageDamage: {
      VisitCargoInspDamageId: '',
      RouteImage: '',
    },

    items: [],
    VisitCargoInspDamageId: '',
    image: '',
    isSubmit: false,
  };
}

function closeModal() {
  this.$emit('CloseModal', true);
  this.refreshComponent();
}

function handleFileUpload(image) {
  this.imageDamage.RouteImage = this.$refs.imageInput.file;
  this.image = image
}

function submitImage() {
  try {
    this.loadingOverlay = true;
    this.isSubmit = true;
    this.$v.imageDamage.$touch();

    if (this.$v.imageDamage.$error) {
      this.loadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let formData = new FormData();
    formData.append('file1', this.imageDamage.RouteImage);
    this.$http.file('VisitInspection-file', this.imageDamage.RouteImage)
    .then((response) => {
      this.imageDamage.RouteImage = response.data.data.files[0].path;
      this.submit();
      this.loadingOverlay = false;
      this.isSubmit = false;
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.loadingOverlay = false;
      this.isSubmit = false;
    }); 
  } catch (e) {
    this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
      });
  }
}

function submit() {
    this.loadingOverlay = true;
      this.isSubmit = true;

    let FilesJson = {
      VisitCargoInspDamageId: this.imageDamage.VisitCargoInspDamageId,
      FileValue: this.imageDamage.RouteImage,
    };

    this.$http.post('VisitCargoInspDamageFile-insert', FilesJson, { root: 'FilesJson' })
    .then(response => {
      if (response.status === (200 || 201)) {
        this.closeModal();
        this.$notify({
          group: 'container',
          title: '¡'+this.$t('label.success')+'!',
          text: response.data.data[0].Response,
          type: "success"
        });
      }
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.loadingOverlay = false;
      this.isSubmit = false;
    }).then(() => {
      this.loadingOverlay = false;
      this.isSubmit = false;
    });
}
function refreshComponent() {
  this.imageDamage.VisitCargoInspDamageId = '';
  this.imageDamage.RouteImage = '';
  this.image = "";
  this.items = '';
  this.titleModal= '';
  this.items= [];
  this.VisitCargoInspDamageId = '';
  if (this.$refs.imageInput) {
    this.$refs.imageInput.removeImage();
    this.$refs.imageInput.value = null;
    this.$refs.imageInput.file = null;
  }
  this.$v.$reset();
}

//computed
function AssignedByOptions() {
  if(this.AssignedByList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    let _lang = this.$i18n.locale;
    this.AssignedByList.map(function(e){
      chart.push({
        value: e.YardStatusSuggestTransactionId,
        label: _lang=='en'? e.TpTransacEirNameEn : e.TpTransacEirNameEs,
      })
    })
    return chart;
  }
}

export default {
  name: 'modal-rr',
  components: {
    PictureInput,
  },
  data,
  props: {
    modal: null,
  },
  mixins: [General],
  directives: UpperCase,
  validations:inspectionValidation,
  methods: {
    closeModal,
    handleFileUpload,
    submit,
    submitImage,
    refreshComponent,
  },
  computed: {
    AssignedByOptions
  },
  watch: {
    modal: async function (val) {
      this.showModal = val;
      if (this.modal) {
        let _lang = this.$i18n.locale;
        this.Route = _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
        this.showImage = val; 
        this.titleModal = '';
      }
    },
  }
};
</script>